


import { Component, Vue, Watch } from "vue-property-decorator";
import stores from "../../../store/store";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

// const store: any = stores;
// Component.registerHooks(["beforeRouteLeave", "beforeRouteEnter"]);

@Component
export default class WelcomeComponent extends Vue {
  public loanTxnId:any = null;
  public borrowerId:any = null;
  public borrowerDeatils: any = null;
  public borrowerName: any = null;

  public get session() {
    return this.$store.state.sessionObject;
  }
  // public get posConfig() {
  //   return this.$store.state.posSessionConfig;
  // }
  // public set posConfig(data) {
  //   this.$store.state.posSessionConfig = data;
  // }

  // /********************************************************************************************************************************************************
  //  *                            GET THE USER NAME OF LOGGED IN USER                                                                                 *
  //  ********************************************************************************************************************************************************/
  // public loggedInBorrowerName() {
  //   this.borrowerName = "";
  //   if (this.session && this.session.userInfo && this.session.userInfo.borrowerInfo) {
  //     let loggedInBorrower = this.session.userInfo.borrowerInfo.find(
  //       a => a.userId.toString() == this.session.userId.toString()
  //     );
  //     if (loggedInBorrower) {
  //       this.borrowerName =
  //         loggedInBorrower.personalInfo.name.firstName +
  //         " " +
  //         loggedInBorrower.personalInfo.name.lastName;
  //     }
  //   }
  // }

  /********************************************************************************************************************************************************
   *                            START THE POS QUESTIONNAIRE FOR PARTICULAR BORROWER                                                                                 *
   ********************************************************************************************************************************************************/
  async onStart() {
    // let conf = this.posConfig;
    // conf.isInProgress = true;
    // conf.loanTxnId = this.loanTxnId;
    // await this.$store.dispatch("updateQuestionHistory", conf);
    // await this.$store.dispatch("saveQuestionnaireHistory", this.posConfig);

    this.$router.push({
      path: this.$getCurrentUserTypePath("pos-questionnaire"),
      query: {
        loanTxnId: this.loanTxnId,
        borrowerId: this.borrowerId
      }
    });
  }

/*********************************************************************************************************************************
 *              This function return logInBorrower Name And Related (borrower/coBorrower Name)
 *********************************************************************************************************************************/
  public async getLogInBorrowerDetails(){
    try{
      let response = await Axios.post(
        BASE_API_URL + "pos/getLogInBorrowerDetails",
        {
          loanTxnId: this.loanTxnId,
          borrowerId: this.borrowerId
        });
      this.borrowerDeatils = response.data;
      this.setBorrowerName();
    }catch(error){
      console.log(error);
    }
  }

  setBorrowerName(){
    if(this.$userType == 'Borrower'){
      if(this.$userId == this.borrowerDeatils.borrower.userId){
        this.borrowerName = this.borrowerDeatils.borrower.name;
      }else{
        this.borrowerName = this.borrowerDeatils.coBorrower.name;
      }
    }else{
      this.borrowerName = this.borrowerDeatils.borrower.name;
    }
  }

  
  /*******************************************************************************************************************************
   *        this page called from (borrower, broker, mlo, AdminStaff) side's                                                     *
   *        If have (loanTxnId and borrowerId) in route.                                                                         *
   *        else used from borrower side                                                                                         *
   *******************************************************************************************************************************/
  async mounted() {
    if(this.$route.query.hasOwnProperty('loanTxnId')){
      this.loanTxnId = this.$route.query.loanTxnId;
      this.borrowerId = this.$route.query.borrowerId;
    }else{
      this.loanTxnId = this.session.userInfo.loanTxnId;
      this.borrowerId = this.session.borrowerId;
    }
    await this.getLogInBorrowerDetails();
    // this.$store.dispatch("getQuestionnaireHistory", {
    //   loanTxnId: this.loanTxnId,
    //   borrowerId: this.borrowerId
    // });
  }
}
